(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/form/assets/javascripts/form.js') >= 0) return;  svs.modules.push('/components/utils/form/assets/javascripts/form.js');
'use strict';

svs.utils = svs.utils || {};
svs.utils.form = svs.utils.form || {};

svs.utils.form.postToUrl = function factorial(path, params, method) {
  method = method || 'post'; 

  var form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  for (var key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};


 })(window);